import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-full  text-black-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[500px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-pink-600">
              About Us
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-5xl font-bold">
            <p>WHO WE ARE:</p>
          </div>
          <div>
            <p>
              AMR – AMR SEVA SANSTHAN WAS START UP IN THE YEAR 2024 BY MR MANISH
              KUMAR BARANWAL AND GROUP OF FRIENDS WAS COME TOGETHER WITH THE
              INTENTION OF THAT SOCIETY EVERY PEOPLE GIVE HEALTH AND JOY AND
              ALSO SAVING OF EXPENSE OF HEALTH RELATED TREATMENT.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
