import React from "react";
import "./Carousel.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = () => {
  const imagesToRender = ["1-c.jpg", "2-c.jpg", "3-c.jpg", "4-c.jpg"];
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      className="carousel-box"
    >
      {imagesToRender.map((src, index) => (
        <div className="showcase-slider-image" key={index}>
          <img
            className="slider-img"
            src={`/${src}`}
            alt={`slider-${index + 1}`}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
