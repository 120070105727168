import React, { useEffect } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Member from "./components/Member";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Hospital from "./components/Hospital";
import Carousel from "./components/Carousel/Carousel";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-R2005K40QM");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <Navbar />
      <div className="overflow-auto relative">
        <Carousel />
        <Home />
        <About />
        <Skills />
        <Hospital />
        <Work />
        <Member />
        <Contact />
      </div>
    </>
  );
}

export default App;
