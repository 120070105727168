import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";

const Home = () => {
  return (
    <div name="home" className="w-full h-full ">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center mt-10">
        <p className="text-pink-600 text-4xl sm:text-3xl">
          AMR Seva-Sansthan Welcomes you,
        </p>
        <h1 className="text-4xl sm:text-3xl font-bold text-[#0d0d0d]">
          Healthy Communities, Stronger Futures
        </h1>
        <h2 className="text-4xl sm:text-5xl font-bold text-[#000000]">
          Join Us in Making a Difference Today
        </h2>
        <p className="text-[#00b33c] py-4 max-w-[700px]">
          AMR Seva-Sansthan: Transforming communities through health. With our
          slogan 'Healthy Communities, Stronger Futures,' we're dedicated to
          providing essential healthcare services and resources. Join us in
          building a healthier, more resilient tomorrow for all.
        </p>
        <div>
          <button className="text-black group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
            View Work
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3 " />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
