import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import LOGO2 from "../assets/LOGO2.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => {
    setNav(!nav);
  };

  useEffect(() => {
    if (nav) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup function to reset overflow when component unmounts or nav is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [nav]);

  return (
    <div className="w-full h-[80px] flex justify-between items-center px-4 bg-[#F8F8FF] text-black-300">
      {/* Hamburger */}
      <div onClick={handleClick} className="block z-20 md:hidden">
        {!nav ? <FaBars /> : <FaTimes className="text-black" />}
      </div>

      <div>
        <img src={LOGO2} alt="Logo" style={{ width: "120px" }} />
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li>
          <Link to="about" smooth={true} duration={500}>
            Who We Are
          </Link>
        </li>
        <li>
          <Link to="skills" smooth={true} duration={500}>
            Aim & Vision
          </Link>
        </li>
        <li>
          <Link to="home" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link to="Hospital" smooth={true} duration={500}>
            Hospital
          </Link>
        </li>
        <li>
          <Link to="work" smooth={true} duration={500}>
            Gallery
          </Link>
        </li>
        <li>
          <Link to="member" smooth={true} duration={500}>
            Founder
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500}>
            Contact Us
          </Link>
        </li>
      </ul>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute z-10 top-0 left-0 w-full h-[calc(100vh+80px)] bg-[#F8F8FF] flex flex-col justify-center items-center"
        }
      >
        {/* Menu items */}
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            WHO WE ARE
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Aim & Vision
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="Hospital"
            smooth={true}
            duration={500}
          >
            Hospital
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Gallery
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="member" smooth={true} duration={500}>
            Founder
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
