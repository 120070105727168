import Qkart from "../assets/projects/gallery1.jpg";
import Qtrip from "../assets/projects/gallery2.jpg";
import Productlist from "../assets/projects/gallery3.jpg";
import Lawfirm from "../assets/projects/gallery4.jpg";
import Newshub from "../assets/projects/E.jpg";
import Qtify from "../assets/projects/F.jpg";

export const data = [
  {
    id: 1,
    name: "Explore More",
    image: Qkart,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
  {
    id: 2,
    name: "Explore More",
    image: Qtrip,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
  {
    id: 3,
    name: "Explore More",
    image: Productlist,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
  {
    id: 4,
    name: "Explore More",
    image: Lawfirm,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
  {
    id: 5,
    name: "Explore More",
    image: Newshub,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
  {
    id: 6,
    name: "Explore More",
    image: Qtify,
    imageLink:
      "https://drive.google.com/drive/folders/112-gJ_gSTVdUKPT3OnqPw-_E-2zrhInB?usp=sharing",
    video:
      "https://drive.google.com/drive/folders/1c-Yc4Obx5KWIYbuuf-qPR3xEmzfp2n0X?usp=sharing",
  },
];
