import React from "react";

import Skill from "../assets/TIMELY FREE HEALTH CHECK-UP.png";
import Environment from "../assets/Environment.png";
import Oldage from "../assets/Old age.png";
import pathology from "../assets/PATHOLOGY CHECK-UP AT SAFFICIENT COST..png";
import Community from "../assets/PEOPLE LIVE IN DIGNITY AND SECURITY..png";
import Water from "../assets/MENSTRUAL HYGIENE WORKSHOP FOR WOMEN.png";
import clean from "../assets/Water.png";
import Dis from "../assets/AWARENESS OF PEOPLE FOR HEALTH AND JOY.png";
import men from "../assets/Big (15).png";
import disease from "../assets/WOMEN DISEASES REDUCE..png";
import medical from "../assets/medical.png";

const Skills = () => {
  return (
    <div name="skills" className="w-full py-8 text-black-300">
      <div className="max-w-[1000px] mx-auto">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600">
            Our Aim
          </p>
          <p className="py-4">AIM OF AMR SEVA SANSTHAN:</p>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div className="flex flex-col items-center shadow-md hover:scale-110 transition duration-300">
            <img className="w-30 mx-auto" src={Dis} alt="Disaster" />
            <p className="my-4 ">AWARENESS OF PEOPLE FOR HEALTH AND JOY</p>
          </div>

          <div className="flex flex-col items-center shadow-md hover:scale-110 transition duration-300">
            <img className="w-30 mx-auto" src={Dis} alt="Disaster" />
            <p className="my-4 ">
              THE PROBLEM WHICH IS GOING ON REGARDING THE MEDICAL TREATMENT
            </p>
          </div>

          <div className="flex flex-col items-center shadow-md hover:scale-110 transition duration-300">
            <img className="w-30 mx-auto" src={Skill} alt="Skills Training" />
            <p className="my-4">TIMELY FREE HEALTH CHECK-UP</p>
          </div>
          <div className="flex flex-col items-center shadow-md hover:scale-110 transition duration-300">
            <img className="w-30 mx-auto" src={Water} alt="Water" />
            <p className="my-4">MENSTRUAL HYGIENE WORKSHOP FOR WOMEN</p>
          </div>
          <div className="flex flex-col items-center shadow-md hover:scale-110 transition duration-300">
            <img className="w-30 mx-auto" src={men} alt="Healthcare" />
            <p className="my-4">
              AWARENESS TO WOMEN FOR PERIOD RELATED DISEASES
            </p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={Community} alt="HTML icon" />
            <p className="my-4">ALL PEOPLE LIVE IN DIGNITY AND SECURITY.</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={pathology} alt="HTML icon" />
            <p className="my-4">PATHOLOGY CHECK-UP AT SAFFICIENT COST.</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={Oldage} alt="HTML icon" />
            <p className="my-4">HELP TO OLD AGE PEOPLE.</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={Environment} alt="HTML icon" />
            <p className="my-4">HOW TO SAVE ENVIRONMENT.</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={clean} alt="HTML icon" />
            <p className="my-4">TO GIVE INFORMATION ABOUT CLEANNESS.</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={disease} alt="HTML icon" />
            <p className="my-4">TO GIVE INFORMATION ABOUT WHICH DIET TO TAKE</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={men} alt="HTML icon" />
            <p className="my-4">
              AWARENESS TO WOMEN FOR PERIOD RELATED DISEASES.
            </p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-30 mx-auto" src={medical} alt="HTML icon" />
            <p className="my-4">
              MEDICAL SERVICE PROVID TO MORE TO MORE PEOPLE AT SAFFICIENT COST
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
