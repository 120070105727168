import React from "react";

const Member = () => {
  return (
    <div
      name="Hospital"
      className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full"
    >
      <div>
        <p className="text-4xl font-bold inline border-b-4 border-pink-600 text-black-300 text-center">
          Connected Hospital
        </p>
      </div>
      <p className="py-6">
        At AMR SEVA SANSTHAN, we are dedicated to fostering better health and
        well-being for all. That's why we've established partnerships with
        select hospitals renowned for their commitment to excellence in
        healthcare. Through these collaborations, we ensure that individuals
        receive top-notch treatment at the most affordable prices possible. Our
        mission is to make quality healthcare accessible to everyone, and by
        connecting with these hospitals, we strive to deliver on that promise.
        With our network of trusted medical facilities, patients can rest
        assured that they will receive the best possible care without breaking
        the bank.
      </p>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 text-center py-8">
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Aashirwad Nursing Home
            <p>
              Discount % <br />
              OPD- 20% <br />
              IPD- 20% <br />
              IPD+Surgery -10% <br />
              Medicine -10%
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Ajanta Poly Clinic & Hospital
            <p>
              Discount % <br />
              OPD- 30% <br />
              IPD- 30% <br />
              IPD+Surgery -10% <br />
              Medicine -10%
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Vidyansh Hospital Surgical Center{" "}
            <p>
              Discount % <br />
              OPD- 50% <br />
              IPD- 30% <br />
              IPD+Surgery -30% <br />
              Medicine -20%
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Shiva Hospital{" "}
            <p>
              Discount % <br />
              OPD- <br />
              IPD- 20% <br />
              IPD+Surgery - <br />
              Medicine -20% <br />
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Indian Hospital
            <p>
              Discount % <br />
              OPD - free <br />
              IPD- 20% <br />
              IPD+Surgery - 20% <br />
              Medicine -20%
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Shri Maha Laxmi Hospital
            <p>
              Discount % <br />
              OPD - free <br />
              IPD- 20% <br />
              IPD+Surgery - 20% <br />
              Medicine -20%
            </p>
          </h5>
        </div>
        <div class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Raj Hospital
            <p>
              Discount % <br />
              OPD - free <br />
              IPD- 20% <br />
              IPD+Surgery - 10% <br />
              Medicine -10%
            </p>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Member;
