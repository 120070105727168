import React from "react";

const Member = () => {
  return (
    <div
      name="member"
      className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full"
    >
      <div>
        <p className="text-4xl font-bold inline border-b-4 border-pink-600 text-black-300 text-center">
          Our Founder :
        </p>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 text-center py-8">
        <a
          href="null"
          class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        >
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            MR.MANISH KUMAR BARANWAL ( B.COM.MBA IN FINANCE , INTER IN CA ,LLB)
          </h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">
            IS THE RESPECTED AND SUCCESS MEN IN THE FIELD OF FINANCE AND
            TAXTATION .HIS VISION THAT EVERY PEPOLE SHOULD BE HEALTHY AND THE
            PROBLEM WHICH IS GOING ON REGARDING THE MEDICAL TREATMENT
            EXPENSES,GET THEM OUT OF THE PROBLEM .
          </p>
        </a>
      </div>
    </div>
  );
};

export default Member;
